import { hideLoading, htmlToElement, showLoading } from '../components/utils.js';
import { generatePaginationButtons, generatePaginationLabel } from '../components/pagination';
import { trackAppliedFiltersCatalogs } from '../analytics/cms.js';

( function() {
	const catalogsForm = document.querySelector( '.m-search__catalog-literature-form' );
	const clearSearchForm = document.querySelector( '.m-search__form-clear-btn' );

	if ( ! catalogsForm ) {
		return;
	}

	catalogsForm.addEventListener( 'submit', async ( e ) => {
		e.preventDefault();
		trackAppliedFiltersCatalogs( catalogsForm );
		await doCatalogSearch();
	} );

	if ( clearSearchForm ) {
		clearSearchForm.addEventListener( 'click', async ( e ) => {
			e.preventDefault();
			const filtersCheckboxes = catalogsForm.querySelectorAll( 'input[type="checkbox"]' );
			if ( ! filtersCheckboxes ) {
				return;
			}
			for ( const filtersCheckbox of filtersCheckboxes ) {
				filtersCheckbox.checked = false;
			}
			await doCatalogSearch();
		} );
	}

	const doCatalogSearch = async ( pageNumber = 1 ) => {
		showLoading();
		const formData = new FormData( catalogsForm );
		const keyword = formData.get( 'keyword' ) ?? '';
		const filterFields = [ ...new Set( Array.from( formData.keys() ) ) ];
		const filtersObject = {};

		for ( const filterField of filterFields ) {
			if ( filterField !== 'keyword' ) {
				filtersObject[ filterField ] = formData.getAll( filterField );
			}
		}

		const rawResponse = await fetch(
			'/wp-json/timken-search/v1/literature',
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify( {
					s: keyword,
					page: pageNumber,
					filters: filtersObject,
				} ),
			},
		);
		const catalogResult = await rawResponse.json();
		const totalItems = catalogResult.total || 0;
		const pageSize = catalogResult.posts_per_page || 0;
		const paginationLabel = catalogsForm.closest( '.m-search' ).querySelector( '.m-filtered-grid__pagination-label' );
		const paginationWrapper = catalogsForm.closest( '.m-search' ).querySelector(
			'.m-search__result-pagination',
		);

		if ( totalItems > 0 ) {
			paginationLabel.innerHTML = generatePaginationLabel(
				pageNumber,
				pageSize,
				totalItems,
			);

			generatePaginationButtons(
				pageSize,
				pageNumber,
				totalItems,
				paginationWrapper,
				doCatalogSearch,
			);
		} else {
			paginationLabel.innerHTML = '';
			paginationWrapper.innerHTML = '';
		}

		populateCatalogData( catalogResult.posts );
		window.scrollTo( {
			top: 0,
			left: 0,
			behavior: 'smooth',
		} );
		hideLoading();
	};

	const populateCatalogData = ( catalogResult ) => {
		const searchWrapper = catalogsForm.closest( '.m-search' );
		const resultWrapper = searchWrapper.querySelector( '.js-catalogs-literature-results' );
		const noResultWrapper = searchWrapper.querySelector( '.js-catalogs-literature-no-results' );

		resultWrapper.innerHTML = '';

		if ( catalogResult && catalogResult.length > 0 ) {
			noResultWrapper.classList.add( 'd-none' );

			catalogResult.forEach( ( c ) => {
				const catalogEntry = `
					<div class="m-filtered-grid__result-entry m-container--enforce-rounded-image
							m-container--bg-color-light-blue m-container--rounded-borders">
						${ c.thumbnail ? `
							<a class="m-filtered-grid__entry-link--${ c.post_type }" target="_blank" href="${ c.url }">
								<figure class="m-filtered-grid__entry-image">
									<img src="${ c.thumbnail }"
										alt="${ c.post_title } cover image">
								</figure>
							</a>
						` : '' }
						<h4 class="m-filtered-grid__entry-title">${ c.post_title }</h4>
						<p class="m-filtered-grid__entry-excerpt">${ c.excerpt }</p>
						<a class="btn m-cta__link btn--tertiary m-filtered-grid__entry-link--${ c.post_type }"
						target="_blank" href="${ c.url }"  cta-type="btn--tertiary" cta-icon="">
							<span class="m-cta__link-text">View</span>
						</a>
					</div>
				`;

				resultWrapper.appendChild( htmlToElement( catalogEntry ) );
			} );
		} else {
			noResultWrapper.classList.remove( 'd-none' );
		}

		if ( window.searchTerm ) {
			window.dataLayer.push({
				event: "site_search_output",
				eng_results_category : "Catalogs & Literature",
				successful_search: catalogResult && catalogResult.length > 0,
				num_of_results: (catalogResult && catalogResult.length > 0) ? catalogResult.length : 0 ,
				search_term: window.searchTerm
			});
		}
	};

	if ( window.searchTerm ) {
		const inputSearch = catalogsForm.querySelector( '.js-catalog-search-input' );
		inputSearch.value = window.searchTerm;
	} else {
		const defaultLanguage = catalogsForm.querySelector( 'input[name="language"][value="English"]' );
		if ( defaultLanguage ) {
			defaultLanguage.checked = true;
		}
	}

	doCatalogSearch().then();
}() );
